import React, { useState } from 'react'
import { Platform, View, ScrollView } from 'react-native';
import { Paragraph, IconButton, Button, Appbar, Portal, Dialog} from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import { useFocusEffect } from '@react-navigation/native';
import { logout } from '../../core/utils';

import DateTimePicker from '@react-native-community/datetimepicker';
import { DatePickerModal, en, registerTranslation } from 'react-native-paper-dates';

//import { ConfirmDialog } from './ConfirmDialog';

if (Platform.OS != 'android') {
  registerTranslation("en", en);
}

import {styles} from './styles'
import Template from './Template';
import SettingsEditor from './SettingsEditor';

const orig_warn = console.warn;
console.warn = function filterWarnings(msg) {
  const supressedWarnings = ['(webpack)', 'useNativeDriver'];

  if (!supressedWarnings.some(entry => msg.includes(entry))) {
//    orig_warn.apply(console, arguments);
  }
};



export default function Assessment({navigation, route}) {
  const [state, setState] = useState(route.params.data);
  const [settingsVisible, setSettingsVisible] = useState(route.params.settingsVisible ? true : false);
  const [settingsEditor, setSettingsEditor] = useState({show: false});
  const [dialog, setDialog] = useState({show: false});

  // General functions ---------------------------------
  useFocusEffect(
    React.useCallback(() => {
      //console.log('Assessment was focused');
      return () => {
        //console.log('Assessment was unfocused');
      };
    }, [])
  );

  React.useEffect(() => {
    // Use `setOptions` to update the button that we previously specified
    // Now the button includes an `onPress` handler to update the count
    navigation.setOptions({
      headerRight: () => (
          <Appbar style={{"height": "1px"}}>
            <IconButton icon="paperclip" onPress={()=>{getfile}}/>
            <IconButton icon="cog" onPress={()=>{showSettings()}}/>
            {/* <IconButton icon="delete" onPress={()=>{setDialog({show:true, msg:"Delete this evaluation?", action:deleteEvaluation})}}/> */}
            {/* <IconButton icon="email" onPress={()=>{setDialog({show:true, msg:"Submit this evaluation?", action:submitEvaluation})}}/> */}
            <IconButton icon="logout" onPress={()=>{logout()}}/>
            {/* <IconButton icon="logout" onPress={()=>{navigation.pop();navigation.push("Login", route.params);}}/> */}
          </Appbar>
      ),
    });
  }, [navigation]);

  const getFile = async () => {
    console.log(window.pdfjs)
    const res = await DocumentPicker.getDocumentAsync();
    if (res.type === "success") {
      console.log("it worked", res);
    } else {
      console.log("it broke", res.type);
    }
  };

  // field functions ---------------------------------------
  const onFieldChange = (name, text, value)=> {
      //console.log("name:" + name + " value:" + value);
      // if(value)
      //   text=text+":"+value

      //console.log("field:" + name);
      //console.log(templateSection);

      if (text === undefined)
        text = (route.params.data[name] == "Y") ? "N" : "Y";
      route.params.data[name] = text;
      //console.log("1:" + name + ":" + text);
      setState(prevState => ({
          ...prevState,
          [name]: text,
          updated: true
      }));
      if (name == "templateID")
        refreshPage();
      //console.log("2:" + name + ":" + text);
  };

  // date functions ---------------------------------------
  function getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }
  
  const [datepicker, setDatepicker] = useState({ "show": false, "label": "", "name": "", "date": new Date() });
  const [yScroll, setYScroll] = useState(0);
  const [myScroll, setMyScroll] = useState(null);

  const onDatepickerChange = (event, selectedDate) => {
    onFieldChange(datepicker.field, getFormattedDate(selectedDate));
  };

  const recordScrollPosition= (event) => {
    setYScroll(event.nativeEvent.contentOffset.y);
    //console.log("YSCROLL:", yScroll);
  }

  const showDatepicker = (label, name, value) => {
    var date = (!value || value == "") ? new Date(): new Date(value);
    if (isNaN(date)) {
      date = new Date();
    }
    setDatepicker({"show": true, "label": label, "name": name, "date": date });
  };

  const hideDatepicker = () => {
    setDatepicker(prevState => ({
      ...prevState,
      "show": false
    }));
  };

  const showSettings = () => {
    route.params["settingsVisible"] = !settingsVisible;
    setSettingsVisible(!settingsVisible);
    refreshPage();
  }

  // const deleteEvaluation = () => {
  //   onFieldChange("status", "deleted");
  //   navigation.pop();
  // }

  // const submitEvaluation = () => {
  //   onFieldChange("status", "submitted");
  //   navigation.pop();
  // }

  // const billEvaluation = () => {
  //   onFieldChange("status", "billed");
  //   navigation.pop();
  // }

  const refreshPage=()=>{
    // This is needed to cause a re-creation (intial re-render) of all elements since we have modified the page fields and not just values.
    
    // Save scroll and group open status
    // Done with scroll handler
    navigation.pop();
    console.log("Refresh called -------------------------")
    navigation.push("Evaluation", route.params);

    // Restore scroll and group open status
    if (myScroll)
      myScroll.scrollTo(yScroll);
  };

  const footer = () =>{
    return(
      <Appbar style={styles.appbar}>
        <Appbar.Action
          icon="logout"
          onPress={() =>  logout}
        />
        {/* <Appbar.Action 
          icon="mail" 
          onPress={() => refreshPage()}
        /> */}
        <Appbar.Action
          icon="paperclip"
          onPress={getFile}
          // onPress={() => navigation.navigate('Attachments')}
        />
        <Appbar.Action
          icon="cog"
          onPress={() => showSettings()}
        />
        {/* <Appbar.Action
          icon="delete"
          onPress={()=>setDialog({show:true, msg:"Delete this evaluation?", action:deleteEvaluation})}
        /> */}
        {/* <Appbar.Action
          icon="email"
          style={route.params.data.status!="open"?{display:"none"}:""}
          onPress={()=>setDialog({show:true, msg:"Submit this evaluation?", action:submitEvaluation})}
        /> */}
        {/* <Appbar.Action
          icon="currency-usd"
          style={route.params.data.status!="submitted"?{display:"none"}:""}
          onPress={()=>setDialog({show:true, msg:"Bill this evaluation?", action:billEvaluation})}
        /> */}
        
      </Appbar>
    );
  }

  return (
    <View style={styles.body}>
      <ScrollView style={styles.section} onScroll={recordScrollPosition} scrollEventThrottle="0" ref={(ref) => setMyScroll(ref) }>
        <Template templateSection={route.params.templates[state["templateID"]]} data={route.params.data} state={state} onFieldChange={onFieldChange} settingsVisible={settingsVisible} setSettingsEditor={setSettingsEditor} showDatepicker={showDatepicker} refreshPage={refreshPage}/>
      </ScrollView>
      {/* {footer()} */}

      {datepicker.show && (Platform.OS === 'android') && (
        <DateTimePicker
          testID="dateTimePicker"
          value={datepicker.date}
          mode={"date"}
          is24Hour={true}
          display="default"
          onChange={(e, selectedDate) => { hideDatepicker(); if (selectedDate) onFieldChange(datepicker.name, getFormattedDate(selectedDate));}}
        />
      )}
      {(Platform.OS != 'android') && (
        <DatePickerModal
          // NTBD - Remove Save and edit, move X to RIght side
          // NTBD - Small screens landscape, calendar does not fit
          // NTBD - Select month quicker.
          locale="en"
          mode="single"
          visible={datepicker.show}
          onDismiss={() => hideDatepicker()}
          date={datepicker.date}
          onConfirm={(params) => { hideDatepicker(); if (params.date) onFieldChange(datepicker.name, getFormattedDate(params.date));}}
          onChange={(params) => { hideDatepicker(); if (params.date) onFieldChange(datepicker.name, getFormattedDate(params.date));}}
          label={datepicker.label}
          // validRange={{
          //   startDate: new Date(2021, 1, 2),  // optional
          //   endDate: new Date(), // optional
          //   disabledDates: [new Date()] // optional
          // }}
          // saveLabel="Save" // optional
          // uppercase={false} // optional, default is true
          // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
        />
      )}

      <SettingsEditor settingsEditor={settingsEditor} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>

      <Portal>
        <Dialog visible={dialog.show} >
          <Dialog.Content>
            <Paragraph>{dialog.msg}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            { dialog.action ? <Button onPress={() => { setDialog({show:false}); dialog.action(); }}>Yes</Button> : <View/> }
            <Button onPress={()=>setDialog({show:false})}>No</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
}
