import { useState } from 'react'
import { View } from 'react-native';
import { IconButton, Portal, Dialog, Paragraph, Button} from 'react-native-paper';
import {styles} from './styles'

export default function Settings({settingsVisible, template, index, setSettingsEditor, refreshPage }) {
  if(!settingsVisible){
    return(<View/>);
  }
  const [dialog, setDialog] = useState({show: false});

  const element = template[index];

  const moveUp = (index)=> {
    if (index > 0) {
      var tmp = template[index];
      template[index] = template[index-1];
      template[index-1] = tmp;
    }
    refreshPage();
  };
  const moveDown = (index)=> {
    if (index < template.length - 1) {
      var tmp = template[index];
      template[index] = template[index+1];
      template[index+1] = tmp;
    }
    refreshPage();
  };
  const addElement = (index)=> {
    const newElement = {"name": "New Field", "id": ("" + (Math.floor(Math.random() * 1000000000000000))), "type": "field"};
    template.splice(index, 0, newElement);
    refreshPage();
  };
  const copyElement = (index)=> {
    template.splice(index, 0, JSON.parse(JSON.stringify(template[index])));
    refreshPage();
  };
  const deleteElement = (index)=> {
    if (template.length > 1) {
      template.splice(index, 1);
      refreshPage();
    }
  };




  return(
    <View style={styles.settings}>
      <IconButton icon="arrow-up" style={styles.settingsIcon} onPress={()=>{moveUp(index)}}/>
      <IconButton icon="arrow-down" style={styles.settingsIcon} onPress={()=>{moveDown(index)}}/>
      <IconButton icon="plus" style={styles.settingsIcon} onPress={()=>{addElement(index)}}/>
      <IconButton icon="content-duplicate" style={styles.settingsIcon} onPress={()=>{copyElement(index)}}/>
      <IconButton icon="delete" style={styles.settingsIcon} onPress={()=>setDialog({show:true, msg:"Delete row?", index: index, action:deleteElement})}/>
      {/* <IconButton icon="delete" style={styles.settingsIcon} onPress={()=>{deleteElement(index)}}/> */}
      {/* {show:true, msg:"Submit this evaluation?", action:submitEvaluation} */}
      <IconButton icon="pencil" style={styles.settingsIcon} onPress={()=>{setSettingsEditor({show:true, template: template, index: index })}}/>
      <Portal>
      <Dialog visible={dialog.show} >
        <Dialog.Content>
          <Paragraph>{dialog.msg}</Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => { if (dialog.action) dialog.action(dialog.index); setDialog({show:false}); }}>Yes</Button>
          <Button onPress={()=>setDialog({show:false})}>No</Button>
        </Dialog.Actions>
      </Dialog>
      </Portal>
    </View>

  );
}
