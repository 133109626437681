import { useState } from 'react'
import { View } from 'react-native';
import { IconButton} from 'react-native-paper';
import {styles} from './styles'

export default function AddToReport({visible, onFieldChange, id }) {
  if(!visible){
    return(<View/>);
  }
  const [on, SetOn] = useState(true);

  
  const setAddToReport = (id)=> {
    SetOn(!on);
  };

  return(
    <View style={styles.addToReport}>
      <IconButton icon="file" style={ on ? styles.addToReportIcon : styles.addToReportIconOff} onPress={()=>{setAddToReport(id)}}/>
    </View>

  );
}
