import React, { useState, useRef } from 'react'
import { StyleProp, ViewStyle, View, ScrollView, Text, Pressable } from 'react-native';
import { Card, TouchableRipple, Paragraph, Menu, Divider, TextInput, Switch, List, Checkbox} from 'react-native-paper';

import {styles} from './styles'
import Settings from './Settings';
import AddToReport from './AddToReport';

export default function Template({templateSection, data, state, onFieldChange, settingsVisible, setSettingsEditor, showDatepicker, refreshPage}) {

  function IDText({visible, element }) {
    //console.log("element:" + element.id);
    if(visible){
      return(<Text style={styles.idText}>{element.id}</Text>);
    } else {
      return(<View/>);
    }
  }
  
    // date functions ---------------------------------------
  function getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  // If no template, then return nothing.
  if (!templateSection)
    return (<View/>);

  return templateSection.map((element, key) => {
    // Handle empty fields, overwrite, [today].
    if (!("id" in element)) {
      element["id"] = "_";
      // The following can help track down missing ids in fields.
      //state[element.id] = JSON.stringify(element);
    }
    if (!("type" in element))
      element["type"] = "text";
    if (!("label" in element))
      element["label"] = "";

    // Set the current state based on existing values.
    if ((element.id in state)) {
      state[element.id] = data[element.id];
    }
    else {
      if ("default" in element)
        state[element.id] = element.default;
      else
        state[element.id] = "";
    }

    // Overwrite the value with the overwrite value EVERY time rendered.
    // Note this is primarily for the "last modified" field
    if ("overwrite" in element)
      state[element.id] = element.overwrite;

    // Special case for substitution of [today] with today's date
    if (!!setSettingsEditor && state[element.id] == "[today]")
      state[element.id] = getFormattedDate( new Date());

    // Back fill the values with our new state.
    data[element.id] = state[element.id];
    
    // Editable by default
    if (!("editable" in element)) {
      element["editable"] = "Y";
    }

    // AddToReport by default
    if (!("addToReport" in element)) {
      element["addToReport"] = "Y";
    }

    // title ------------------------------------------------------
    if (element.type=="title"){
      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <Text style={[styles.title, styles.autoWidth]}>{element.label}</Text>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // text ------------------------------------------------------
    else if (element.type=="text"){
      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <View style={[styles.row, styles.autoWidth]}>
            <Text style={[styles.text, styles.autoWidth]}>{element.label}</Text>
            <Text style={styles.textValue}>{state[element.id]}</Text>
          </View>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
          {/* <View style={styles.rightSection}>
          </View> */}
        </View>
      );
    }

    // field ------------------------------------------------------
    else if (element.type=="field") {
      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <View style={[styles.input, styles.autoWidth]} >
            <TextInput
              key={element.id}
              label={element.label}
              style={styles.input}
              mode="outlined"
              dense="true"
              value={state[element.id]}
              onChangeText={(text) => {onFieldChange(element.id, text)}}
              placeholder={element.placeholder}
              multiline={ ("multiline" in element && element.multiline != '') ? true : false}
              numberOfLines={("multiline" in element && element.multiline != '') ? parseInt(element.multiline) : 1}
              // editable = {(element.editable)&&(element.editable != "Y") || (element.editable == "1")}
              editable = {(element.editable != "N")}

            />
          </View>
          <AddToReport visible={element["addToReport"] == "Y"} onFieldChange={onFieldChange} id={element.id}/>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // date ------------------------------------------------------
    // NTBD - validation of date?
    else if (element.type=="date") {
      return(
        <View key={key}  style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <View style={[styles.input, styles.autoWidth]} >
          <Pressable onPress={()=>{if (!("editable" in element) || (element.editable == "N")) showDatepicker(element.label, element.id, state[element.id])}}>
            <TextInput
              pointerEvents={(!("editable" in element) || (element.editable == "N")) ? "none" : "auto"}
              key={element.id}
              label={element.label}
              style={styles.input}
              mode="outlined"
              dense="true"
              value={state[element.id]}
              onChangeText={(text) => {onFieldChange(element.id, text)}}
              placeholder={element.placeholder}
              right={<TextInput.Icon onPress={()=>{showDatepicker(element.label, element.id, state[element.id])}} icon="calendar" />}
              editable = {("editable" in element) && (element.editable == "Y")}
            />
          </Pressable>
          </View>
          <AddToReport visible={element["addToReport"] == "Y"} data={data} id={element.id}/>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // checkbox ------------------------------------------------------
    else if (element.type=="checkbox"){
      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <TouchableRipple style={styles.autoWidth}  onPress={() => onFieldChange(element.id, (state[element.id] == "1") ? "0" : "1")}>
            <View style={styles.row}>
              <Checkbox
                key={element.id}
                status={(state[element.id] == "Y" || state[element.id] == "1") ? "checked" : "unchecked"} />
              <Paragraph  style={styles.checkbox}>{element.label}</Paragraph>
            </View>
          </TouchableRipple>
          <AddToReport visible={element["addToReport"] == "Y"} data={data} id={element.id}/>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // switch ------------------------------------------------------
    else if (element.type=="switch"){
      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <TouchableRipple style={styles.autoWidth} onPress={() => onFieldChange(element.id, (state[element.id] == "Y") ? "N" : "Y")}>
            <View style={styles.row}>
              <Paragraph  style={styles.autoWidth}>{element.label}</Paragraph>
              <Switch
                key={element.id}
                value={(state[element.id] == "Y") ? true : false }
                onValueChange={(value) => onFieldChange(element.id, (value) ? "Y" : "N")}
              />
            </View>
          </TouchableRipple>
          <AddToReport visible={element["addToReport"] == "Y"} data={data} id={element.id}/>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // group ------------------------------------------------------
    else if (element.type=="group"){
      // Use local state here as we do not want to save the expanded state permanently.
      const [expanded, setExpanded] = useState(("expanded" in element) && (element.expanded == "Y"));
      const value = ()=>{return element.items.map((item, index)=>{
        if(item.options&&data[item.id]){
          return data[item.id].split(":")[1]
        }
      })}
      var sum=value().reduce((partialSum, a) => parseInt(partialSum) + parseInt(a));

      // console.log(value())
      // console.log(value().reduce((partialSum, a) => partialSum + a))

      return (
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <View style={[styles.group, styles.autoWidth]} >
            <List.Section>
              <List.Accordion
                style={styles.accordian}
                key={element.id}
                title={element.label}
                // left={props => <List.Icon {...props} icon="folder" />}
                expanded={expanded}
                onPress={() => setExpanded(!expanded)}>
                  <Template templateSection={element.items} data={data} state={state} onFieldChange={onFieldChange} settingsVisible={settingsVisible} setSettingsEditor={setSettingsEditor} showDatepicker={showDatepicker} refreshPage={refreshPage}/>
                  {/* <View style={[styles.row, styles.autoWidth]}>
                    <Text style={[styles.text, styles.autoWidth]}>sum</Text>
                    <Text style={styles.textValue}>{sum?sum:""}</Text>
                  </View> */}
              </List.Accordion>
            </List.Section>
          </View>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }  

    // selection ------------------------------------------------------
    else if (element.type=="selection") {
      // Use local state here as we do not want to save the expanded state permanently.
      const [expanded, setExpanded] = useState(element.expanded);
      const [editable, setEditable] = useState(false);
      const input_ref = useRef();
      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <Pressable style={styles.autoWidth} onPress={()=>{if (!editable) setExpanded(true)}}>
            <Menu
              visible={expanded}
              onDismiss={() => {setExpanded(false)}}
              style={styles.menu}
              anchor={
                  <TextInput
                    pointerEvents={(!editable) ? "none" : "auto"}
                    key={element.id}
                    label={element.label}
                    style={styles.input}
                    mode="outlined"
                    dense="true"
                    value={state[element.id]}
                    onBlur={(e)=>{setEditable(false);}}
                    onChangeText={(text) => {onFieldChange(element.id, text)}}
                    placeholder={element.placeholder}
                    editable={editable}
                    ref={input_ref}
                  />
              }>
              { ("options" in element) && element.options.split("|").map((option, num) => {
                //var subkey = key + "_sub_" + num;
                var subkey = key + "_sub_" + num + "_" + element.id;// + Math.random();
                // const [text, value] = option.split(":")
                // if (value == undefined)
                //   value = text;
                const regexp = new RegExp("^" + option + "$", "g");
                if (state[element.id] && state[element.id].match(regexp))
                {
                  return <List.Item
                    key={subkey}
                    left={props => <List.Icon  {...props} icon="check" />}
                    title={option}
                    style={styles.menuItem} 
                    onPress={() => { setExpanded(false); onFieldChange(element.id, option)}}
                  />
                }
                else if (option == "[divider]")
                  return <Divider key={subkey}/>
                else if (option == "[other]")
                  return <List.Item 
                    key={subkey} 
                    left={props => <List.Icon  {...props} icon="" />}
                    title="Other"
                    style={styles.menuItem} 
                    onPress={() => { setExpanded(false); setEditable(true); setTimeout(() => { input_ref.current.focus(); }, 300); }}
                  />
                else
                  return <List.Item
                    key={subkey} 
                    left={props => <List.Icon  {...props} icon="" />} 
                    title={option} 
                    style={styles.menuItem}
                    onPress={() => { setExpanded(false); onFieldChange(element.id, option)}}
                  />
              })}
            </Menu>
          </Pressable>
          <AddToReport visible={element["addToReport"] == "Y"} data={data} id={element.id}/>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // combobox ------------------------------------------------------
    else if (element.type=="combobox") {
      // Use local state here as we do not want to save the expanded state permanently.
      const lines = (element.id && state[element.id] && state[element.id] != '') ? (state[element.id].match(/\|/g) || '').length + 1 : 1;
      const [expanded, setExpanded] = useState(element.expanded);
      const [editable, setEditable] = useState(false);
      const input_ref = useRef();
      const multiSelect=()=>{
        if(element.multiSelect=="N"){
          console.log(element.multiSelect)
          return setExpanded(false);
        }
      }
      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <Pressable style={styles.autoWidth} onPress={()=>{if (!editable) setExpanded(true)}}>
            <Menu
              visible={expanded}
              onDismiss={() => {setExpanded(false)}}
              style={styles.menu}
              // mode="outlined"
              anchor={
                  <TextInput
                    pointerEvents={(!editable) ? "none" : "auto"}
                    key={element.id}
                    label={element.label}
                    style={styles.input}
                    mode="outlined"
                    dense="true"
                    // value={state[element.id].replaceAll("|", "\n")}
                    // TypeError: state[element.id].replaceAll is not a function.
                    value={state[element.id].split("|").join("\n")}
                    onBlur={(e)=>{setEditable(false);}}
                    // onChangeText={(text) => {onFieldChange(element.id, text.replaceAll("\n", "|"))}}
                    onChangeText={(text) => {onFieldChange(element.id, text.replaceAll("\n", "|"))}}

                    placeholder={element.placeholder}
                    multiline={true}
                    numberOfLines={lines}
                    editable={editable}
                    ref={input_ref}
                  />
              }>
              { ("options" in element) && element.options.split("|").map((option, num) => {
                var subkey = key + "_sub_" + num + "_" + element.id;// + Math.random();
                //console.log("key:" + num);
                const regexp = new RegExp("(^|[\\|]+)" + option + "([\\|]+|$)", "g");
                if (state[element.id] && state[element.id].match(regexp))
                {
                  return <View>
                    <List.Item
                      key={subkey} 
                      left={props => <List.Icon {...props} icon="check" />} 
                      title={option}
                      style={styles.menuItem}  
                      onPress={() => { multiSelect(); const l=state[element.id]; onFieldChange(element.id, state[element.id].replace(regexp, "|").replace(new RegExp("(^[\\|]+)|([\\|]+$)", "g"), ""))}}
                    />
                  </View>
                }
                else if (option == "[divider]")
                  return <Divider key={subkey}/>
                else if (option == "[other]")
                  return <List.Item
                    key={subkey} 
                    left={props => <List.Icon  {...props} icon="" />}
                    title="Other"
                    style={styles.menuItem}  
                    onPress={() => { setExpanded(false); setEditable(true); setTimeout(() => { input_ref.current.focus(); }, 300); }}
                    //onPress={() => { multiSelect(); onFieldChange(element.id, ""); setTimeout(() => { onFieldChange(element.id, state[element.id] + (state[element.id].length ? "|" : "")); setEditable(true); setTimeout(() => { input_ref.current.focus(); }, 100)}, 100); }}
                  />
                else
                  return <List.Item
                    key={subkey} 
                    left={props => <List.Icon  {...props} icon="" />} 
                    title={option}
                    style={styles.menuItem} 
                    onPress={() => { multiSelect(); const l=state[element.id]; onFieldChange(element.id, l.length ? l + "|" + option : option)}}
                  />
              })}
            </Menu>
          </Pressable>
          <AddToReport visible={element["addToReport"] == "Y"} data={data} id={element.id}/>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // image ------------------------------------------------------
    else if(element.type=="image"){
      const img={ uri: state[element.id] };
      console.log("img:" + state[element.id])

      return(
        <View key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <Card style={styles.imgCard}>
            <Card.Content style={{backgroundColor:"white"}}>
              <Card.Cover style={{backgroundColor:"white"}}  resizeMode="cover" source={img?img:img} />
            </Card.Content>
            <Card.Title style={{margin:"auto"}} subtitle={element.label}/>
          </Card>
          <AddToReport visible={element["addToReport"] == "Y"} data={data} id={element.id}/>
          <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
        </View>
      );
    }

    // unknown type found ------------------------------------------------------
    else {
      return(
        <ScrollView key={key} style={styles.row}>
          <IDText visible={settingsVisible} element={element}/>
          <Text style={styles.text}>Unknown type: {element.type}</Text>
          <Text style={styles.text}>{element.label}</Text>
          <View style={styles.rightSection}>
            <Text style={styles.textValue}>{state[element.id]}</Text>
            <AddToReport visible={element["addToReport"] == "Y"} data={data} id={element.id}/>
            <Settings settingsVisible={settingsVisible} template={templateSection} index={key} setSettingsEditor={setSettingsEditor} refreshPage={refreshPage}/>
          </View>
        </ScrollView>
      );
    }


  });
};
