import React, { memo, useState } from 'react';
import { TouchableOpacity, StyleSheet, Text, View, Image} from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import { logout, nameValidator, emailValidator, passwordValidator } from '../../core/utils';

export default function PasswordReset( {navigation} ){
  const Logo = require("../../psych_assist.png");

  const [email, setEmail] = useState({ value: '', error: '' });

  const _onSendPressed = () => {
    const emailError = emailValidator(email.value);

    if (emailError) {
      setEmail({ ...email, error: emailError });
      return;
    }

    navigation.navigate('LoginScreen');
  };

  const BackButton = () => {
    return(
      <TouchableOpacity 
        style={styles.container}
        onPress={() => logout}
      >
        {/* <Image style={styles.image} source={require('../assets/arrow_back.png')} /> */}
      </TouchableOpacity>
    )
  };

  return (
    <View>
      {/* {BackButton()} */}

      <Image source={Logo} resizeMode="contain" style={styles.image}/>
      {console.log(Logo)}
      <Text style={styles.header}>Psych Assist</Text>

      <TextInput
        label="E-mail address"
        returnKeyType="done"
        value={email.value}
        style={styles.input}
        onChangeText={text => setEmail({ value: text, error: '' })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />

      <Button mode="contained" onPress={_onSendPressed} style={styles.button}>
        Send Reset Instructions
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  back: {
    width: '100%',
    marginTop: 12,
  },
  header: {
    textAlign:"center",
    paddingBottom:50,
    fontWeight: "bold",
    fontSize:40
  },
  button: {
    marginTop: 12,
  },
  label: {
    // color: theme.colors.secondary,
    width: '100%',
  },
  image: {
    flex: 1,
    // margin: 20,
  },
  input:{
    margin:10
  }
});