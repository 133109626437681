import React, { useState, useRef, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { Appbar } from 'react-native-paper';

// JODIT Editor
// Installed with "npm i jodit-react"
// https://www.npmjs.com/package/jodit-react
// https://xdsoft.net/jodit/docs/
import JoditEditor from 'jodit-react';

import { styles } from '../styles'
import { logout, saveFile } from '../../../core/utils';

export default function ReportEditor({navigation, route}) {
     const html2pdf = require("../../../core/html2pdf/html2pdf.bundle.min.js");
     const Blob = require("../../../core/html-docx/Blob.js");
     const FileSaver = require("../../../core/html-docx/FileSaver.js");
     const htmldocx = require("../../../core/html-docx/html-docx.js");

     const [filePath, setFilePath] = useState("");
     const editor = useRef(null);
	const [contents, setContents] = useState("Downloading content...");
     const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/docs/,
		placeholder: 'Start typings...'
          };

     useEffect(() => {
          // On load
          //{<script src={html2pdf}></script>}
          //console.log("before calling window.test");
          //window.test();
          //console.log("after calling window.test");

          // {"fileData": 
          // {
          //     "title": "AutismPreschoolReport",
          //     "urlx": "https://psychassist.providentpathways.com/templates/files/AutismPreschoolReport.html",
          //     "url": "reports/default.html",
          //     "saveUrlx": "https://psychassist.providentpathways.com/upload_json_action",
          //     "saveUrl": "https://psychassist.providentpathways.com/upload_json_action",
          //     "fileNamex": "AustismPreschoolReport.html",
          //     "fileName": "default.html"
          // }
          // }
          
          if ("fileData" in route.params) {
               setFilePath("templates/" + route.params.fileData.fileName);
          } else if ("data" in route.params) {
               setFilePath("reports/" + "default.html");
               // const element = route.params.data;
               // if (!("report" in element)) {
               //      console.log("LOADING")
               //      console.log(load_url);
               //      fetch(load_url, {cache: "no-store"})
               //           .then(response => response.text())
               //           .then(data => setContents(data))
               //           .catch(error => setContents("Download of " + load_url + " failed."));
               //                setFilePath("templates/" + route.params.fileData.fileName);
               // } else {
               //      setFilePath("templates/" + route.params.fileData.fileName);
               // }
               // alert("data in element");
               // console.log(route.params.data);
          } else {
               alert("no data in route.params");
          }
 
//          load(filePath);
          load("templates/default.html");

          return () => {
               // On unload
               //document.body.removeChild(script);
          }
     }, []);


     const load = (path) => {
          alert("path:" + path);
          const rootPath = window.pa.root + '/data/' + window.pa.username + '/';
          const load_url = rootPath + path;


          console.log("LOADING")
          console.log(load_url);
          fetch(load_url, {cache: "no-store"})
               .then(response => response.text())
               .then(data => setContents(data))
               .catch(error => setContents("Download of " + load_url + " failed."));
     }
 

     const savePDF = (pdfname) =>{
          var element = document.getElementById("out");
          if (element) {
               //console.log("pushing to out");
               //const newContents = editor.current.value;
               //const newContents = editor.value;
               //html = contents.srcElement.innerHTML;

               element.innerHTML = contents;
               console.log(contents);
               var opt = {
                    margin: [0.5, 0.5, 0.5, 0.5],
                    filename:    pdfname + '.pdf',
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2, letterRendering: true },
                    jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait', putTotalPages: true },
                    pagebreak:    { mode: ['avoid-all', 'css', 'legacy'] }
               };
               // html2pdf().set(opt).from(element).save();
               
               html2pdf().set(opt)
                    .from(element)
                    .toPdf()
                    .get('pdf')
                    .then(function(pdf) {
                          var totalPages = pdf.internal.getNumberOfPages();
                          console.log("---PDF pages:" + totalPages);
                          for (var i = 2; i <= totalPages; i++) {
                              console.log("---PDF page:" + i);
                              pdf.setPage(i);
                             pdf.setFontSize(10);
                             pdf.setTextColor(100);
                             //pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 0.8));
                             pdf.text(' Neurodevelopmental Evaluation Report', (0.5), (0.5));
                             pdf.text('Page ' + i + ' of ' + totalPages + ' ', (pdf.internal.pageSize.getWidth() - 1.25), (0.5));
                             pdf.setLineWidth(0.01);
                             pdf.rect(0.5, 0.35, pdf.internal.pageSize.getWidth() - 1.0, 0.2, "S");
                         }
                     })
                    .save();

          }
     }
     
     const uploadFile = () =>{

          saveFile("reports/" + route.params.fileData.fileName, contents);
          return;

          const formData  = new FormData();
          formData.append("json_path", path);
          formData.append("json_name", json_name);
          formData.append("json_text", json_text);
          //console.log(formData)
          fetch(save_url, {method: 'POST', body: formData})
              .then(response => response.json())
              .then(data => {
                  //console.log("successful upload");
                  //console.log(data);
              })
              .catch(error => {
                  console.log("failed upload");
                  console.log(error);
              });
        
      

          const path = window.pa.root + '/data/' + window.pa.username;
          const save_url = path + "/upload_json_action.php";
          var xhr = new XMLHttpRequest();
          xhr.open("POST", save_url);
          xhr.onreadystatechange = function() {
               if (xhr.readyState === 4) {
                    if (xhr.status === 200)
                    {
                         console.log("Saved " + save_url + xhr.responseText);
                    } else {
                         console.log("SAVE FAIL:" + xhr.responseText);
                         console.log("readyState:" + xhr.readyState);
                         console.log("status:" + xhr.status);
                    }
               }
          }
          xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          xhr.send("file_name=" + encodeURIComponent(route.params.fileData.fileName) + "&file_text=" + encodeURIComponent(contents));
          console.log("save " + route.params.fileData.fileName + " sent");
     }

     const footer = () =>{
          return(
            <Appbar>
              <Appbar.Action
                icon="logout"
                onPress={() =>  logout }
              />
              {/* <Appbar.Action 
                icon="mail" 
                onPress={() => refreshPage()}
              /> */}
              <Appbar.Action
                icon="printer"
                onPress={() => savePDF()}
              />
              <Appbar.Action
                icon="content-save"
                onPress={() => uploadFile()}
              />
              <Appbar.Action
                icon="cancel"
                onPress={() => navigation.pop()}
              />
              
            </Appbar>
          );
     }

     return (
          <View style={styles.body}>
          <ScrollView className="text-editor">
               <JoditEditor
                    hidePoweredByJodit={true}
                    ref={editor}
                    value={contents}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContents(newContent)} // preferred to use only this option to update the content for performance reasons
                    //onChange={newContent => {}}
               />
               <div id="out"></div>
          </ScrollView>
          {footer()}
          </View>
     );
}