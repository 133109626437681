import AsyncStorage from '@react-native-async-storage/async-storage';


export const notify = (msg) => {
    alert(msg);
}

export const emailValidator = (email) => {
    const re = /\S+@\S+\.\S+/;

    if (!email || email.length <= 0) return 'Email cannot be empty.';
    if (!re.test(email)) return 'Ooops! We need a valid email address.';

    return '';
};
  
export const passwordValidator = (password) => {
    if (!password || password.length <= 0) return 'Password cannot be empty.';

    return '';
};
  
export const nameValidator = (name) => {
    if (!name || name.length <= 0) return 'Name cannot be empty.';
  
    return '';
};

const SuccessfulDownload = async (data, type, update) => {

    if (type == "entries") {
        window.pa.clients = data.entries;
    } else {
        window.pa.templates = data.templates;
    }
    console.log("preupdate:" + type);
    update();
    console.log("Downloaded " + type);
    window.pa.downloaded = true;

    /*
    console.log("Successful Download Start")
    //console.log(data);
    try {
      const jsonValue = JSON.stringify(data)
      await AsyncStorage.setItem('@psychAssist', jsonValue)
      setDownloaded(true);

    } catch (e) {
      console.log("Successful Download ERROR" + e);
    }
    if (!readLocalOnly) {
      //console.log(psychAssist.clients[0]);
      setPsychAssist(data);
      //console.log(psychAssist.clients[0]);
    }
    console.log("Successful Download End")
    */
}

const FailedDownload = async (error, url, update) => {
    /*
    setDownloaded(false);
    //console.log("Failed Download Start")
    try {
      if (psychAssist == {} ) {
          console.log("Failed Download - local storage - Start")
          const jsonValue = await AsyncStorage.getItem('@psychAssist')
          if (jsonValue != null) {
              const data = JSON.parse(jsonValue);
              //console.log(data);
              if (!readLocalOnly)
                setPsychAssist(data);
              console.log("Failed Download - local storage - End")
          } else {
            console.log("Failed Download - local storage - FAIL 2")
          }
      }
    } catch(error) {
      console.log("Failed Download - local storage ERROR - Start")
      alert("can't connect to server");
    }
    console.log("Failed Download End")
    */
    notify("Failed download: " + url);
    logout();
}



export const saveData = (json_name, json_text) => {
    // GET request using fetch inside useEffect React hook
    // psych-assist/F2JRKt8yBXs9rS7
    // Create database named psych-assist, then create user named psych-assist and assign it to the database with all priviledges.

    //const url = 'https://' + username + ':' + password + '@psychassist.providentpathways.com/psychAssist.json';
    //const url = 'https://psychassist.providentpathways.com/psychAssist.json';
    //const url = process.env.PUBLIC_URL + '/data/' + window.paUser + "/templates.json";
    const path = window.pa.root + '/data/' + window.pa.username;
    const save_url = path + "/upload_json_action";
    console.log("SAVE:" + save_url);

    /*
    // Save previous changes by uploading first, and then combined changes are downloaded.
    var xhr = new XMLHttpRequest();
    xhr.open("POST", save_url);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            if (xhr.status === 200)
            {
                console.log("Saved psychAssist.json: " + xhr.responseText);
                fetch(url, {cache: "no-store"})
                    .then(response => response.json())
                    .then(data => SuccessfulDownload(data))
                    .catch(error => FailedDownload());
            }
            else {
                console.log("SAVE FAIL:" + xhr.responseText);
                console.log("readyState:" + xhr.readyState);
                console.log("status:" + xhr.status);
            }
        }
    }
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    //console.log(psychAssist.clients);
    xhr.send("json_path=" + encodeURIComponent(path) + "&json_name=" + encodeURIComponent(json_name) + "&json_text=" + encodeURIComponent(json_text));

    */

    const formData  = new FormData();
    formData.append("json_path", path);
    formData.append("json_name", json_name);
    formData.append("json_text", json_text);
    //console.log(formData)
    fetch(save_url, {method: 'POST', body: formData})
        .then(response => response.json())
        .then(data => {
            //console.log("successful upload");
            //console.log(data);
        })
        .catch(error => {
            console.log("failed upload");
            console.log(error);
        });
  


    //console.log("save sent");
}

export const saveFile = (name, text) => {
    const path = window.pa.root + '/data/' + window.pa.username;
    const save_url = path + "/upload_file_action";
    console.log("SAVE:" + save_url);

    const formData  = new FormData();
    formData.append("path", path);
    formData.append("filename", name);
    formData.append("contents", encodeURIComponent(text));
    //console.log(formData)
    fetch(save_url, {method: 'POST', body: formData})
        .then(response => { response.text })
        .then(data => {
            //console.log("successful upload");
            //console.log(data);
        })
        .catch(error => {
            //console.log("failed upload");
            //console.log(error);
        });
  


    //console.log("save sent");
}


export const loginCheck = async (navigation, update) => {
    
    window.pa.username = await AsyncStorage.getItem('@paUser');
    window.pa.sessionid = await AsyncStorage.getItem('@paSessionid');
    window.pa.navigation = navigation;

    const path = window.pa.root + '/data/' + window.pa.username + '/';

    //console.log("static path:[" + process.env.PUBLIC_URL + "]");
    if (!window.pa.username || !window.pa.sessionid) {
        console.log("loginCheck - no username or password.")
        window.pa.downloaded = false;
        window.pa.templates = {};
        window.pa.clients = [];
        navigation.navigate('Login', {});
    } else if (window.pa.downloaded) {
        //console.log("saving to:" + path + "/templates.json");
        saveData("templates.json", JSON.stringify({"templates": window.pa.templates}, null, 2));
        //console.log("saving to:" + path + "/entries.json");
        saveData("entries.json", JSON.stringify({"entries": window.pa.clients}, null, 2));
        update();
    } else {
                var url1 = path + "entries.json";
                console.log("url1:" + url1);
                fetch(url1, {cache: "no-store"})
                    .then(response => response.json())
                    .then(data =>  SuccessfulDownload(data, "entries", update))
                    .catch(error => FailedDownload(error, url1, update));
                var url2 = path + "templates.json";
                console.log("url2:" + url2);
                fetch(url2, {cache: "no-store"})
                    .then(response => response.json())
                    .then(data => SuccessfulDownload(data, "templates", update))
                    .catch(error => FailedDownload(error, url2, update));
    }
    //setState(true);

}

export const login = async (username, password, sessionid, navigation) => {
    await AsyncStorage.setItem('@paUser', username);
    await AsyncStorage.setItem('@paSessionid', sessionid);
    window.pa.username = username;
    window.pa.sessionid = sessionid;
    window.pa.navigation = navigation;

    const path = window.pa.root + '/data/' + window.pa.username + '/';
    console.log("login: " + path);
    var url0 = path + "password";
    fetch(url0, {cache: "no-store"})
        .then(response => response.text())
        .then(data =>  {
            //console.log("data:" + data + " password:" + password);
            if (data != password) {
                notify("Username or password incorrect.")
                logout();
                return;
            }
            navigation.navigate('Home', {username: username.value, password: password.value});
        })
        .catch(error => FailedDownload(error, url0, update));
}

export const logout = async (navigation) => {
    await AsyncStorage.setItem('@paSessionid', null);
    window.pa.sessionid = null;
    if (window.pa.navigation)
        window.pa.navigation.navigate('Login', {"username": window.pa.username});
}

export const init = () => {
    window.pa = {
        navigation: false,
        username: false,
        sessionid: false,
        clients: [],
        templates: [],
        newClient: {},
        downloaded: false,
        root: 'https://psychassist.providentpathways.com'
        //root: ''
    };

    console.log("init commplete");

}