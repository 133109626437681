import React from 'react';
import { View } from 'react-native';
import { MD3LightTheme as DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from './components/Home/Home';
import Assessment from './components/Assessment/Assessment';
import Login from './components/Login/Login';
import PasswordReset from './components/PasswordReset/PasswordReset';
import Attachments from './components/Assessment/Attachments/Attachments';
import ReportEditor from './components/Assessment/Attachments/ReportEditor';
import importScript from './core/importScript';
//import PDFParser from "pdf2json";
const pdfjsLib = require("pdfjs-dist/legacy/build/pdf.js");
const pdfjsWorker = require("pdfjs-dist/legacy/build/pdf.worker.entry.js");
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
//import pdfjsLib from "pdfjs-dist/build/pdf";
//import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { init } from './core/utils';


//import * as PDFJS from 'pdfjs-dist';
//// @ts-ignore
//import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
//PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

import { StyleSheet, Button } from 'react-native';
import "./App.css";

const Stack = createNativeStackNavigator();

export default function App() {
  const showSettings = () => {
    route.params["settingsVisible"] = !settingsVisible;
    setSettingsVisible(!settingsVisible);
    refreshPage();
    // console.log("test")
  }

  init();

  return (
    <PaperProvider theme={theme}>
      <NavigationContainer>
        <Stack.Navigator initialRouteName="Login">
          <Stack.Screen name="Login" component={Login} options={{ title: "" , headerShown: false}}/>
          <Stack.Screen name="PasswordReset" component={PasswordReset} options={{ title: "Password Reset" }}/>
          <Stack.Screen name="Home" component={Home} options={{ title: "", headerShown: false}}/>
          <Stack.Screen name="Evaluation" component={Assessment}/>
          <Stack.Screen name="Attachments" component={Attachments}/>
          <Stack.Screen name="ReportTemplateEditor" component={ReportEditor} options={{title: "Edit Report Template"}}/>
          <Stack.Screen name="ReportEditor" component={ReportEditor} options={{title: "Edit Report"}}/>
          <Stack.Screen name="ReportViewer" component={ReportEditor} options={{title: "View Report"}}/>
        </Stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
}

const theme = {
  ...DefaultTheme,
  roundness: 1,
  colors: {
    ...DefaultTheme.colors,

    //primary: 'tomato',
    //secondary: 'yellow',
//    primary: '#000000',
//    accent: '#00FF00',
//    primary: '#3498db',
//    accent: '#f1c40f',
  },
};
