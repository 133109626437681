import React, { useState } from 'react';
import { FlatList, Text, View, StyleSheet, Pressable} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Appbar, Divider, useTheme, Provider, FAB, List, Button, IconButton, Menu, TextInput, Dialog, Portal, Paragraph} from 'react-native-paper';
import { TabsProvider, Tabs, TabScreen, useTabIndex, useTabNavigation } from 'react-native-paper-tabs';
import { v4 as uuidv4 } from 'uuid';
import { loginCheck, notify, logout } from '../../core/utils';
import { createReport } from '../../core/createReport';
import { getFontNameToFileMap } from 'pdfjs-dist/legacy/build/pdf.worker';


export default function Home({ navigation, route }) {
  const [category, setCategory] = useState("");
  const [updated, setUpdated] = useState(0);
  const [dialog, setDialog] = useState({show: false});

  useFocusEffect(
    React.useCallback(() => {
      console.log('Home was focused');
      //console.log("DOWNLOADED:" + window.pa.downloaded);
      loginCheck(navigation, () => {setUpdated(updated+1);});
      //console.log("after logincheck");
      //console.log("paUser:" + window.paUser);
      //console.log("paSessionid:" + window.paSessionid);

      return () => {
        //setState(false);
        //console.log('Home was unfocused');
      };
    }, [])
  );

  const CreateNewAssessment = () => {
    var element = {};
    element.uuid = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
    //console.log("UUID=" + element.uuid);
    element.status = "";
    element.updated = true;
    element.templateID = Object.keys(window.pa.templates)[0];
    //console.log("default template:" + Object.keys(window.pa.templates)[0]);
    //element["due_date"] = "[today]";
    //element["name_last"] = "";
    //element["name_first"] = "";
    window.pa.clients.push(element);
    setUpdated(updated+1);
    navigation.navigate('Evaluation', { data: element, templates: window.pa.templates })
    window.goToTab(0);
  }


  const TAB_OPEN = 0;
  const TAB_REVIEW = 1;
  const TAB_ARCHIVED = 2;
  const changeCategory=(element, newCategory, _goToTab)=>{
    if (newCategory == "review") {
      var result = createReport(element);
      if (result == false) {
        alert("error creating report");
        return;
      }
    }
  //window.paClients[index].status=newCategory;
    element.status = newCategory;
    console.log("change category element:" + element.uuid + ", newcat:" + newCategory)
    setCategory(newCategory);
    setUpdated(updated+1);
    var index = TAB_OPEN;
    if (newCategory == "review") {
        index = TAB_REVIEW;
    } 
    else if (newCategory == "printed") index = TAB_PRINTED;
    //else if (newCategory == "billed") index = TAB_BILLED;
    else if (newCategory == "archived") index = TAB_ARCHIVED;
    //console.log("index:" + index);

    loginCheck(navigation, () => {});

    if (_goToTab)
      _goToTab(index);
  }
  //title={element["due_date"] + " : " + element["name_last"] + ", " + element["name_first"] + element.uuid}

  const deleteRecord = (element) => {
    console.log("delete: " + element["uuid"])
    //changeCategory(element, "review");

    window.pa.clients = window.pa.clients.filter(item => item["uuid"] != element["uuid"]);
    setUpdated(updated+1);
  }

  const statusButtons = (element) => {
    const [expanded, setExpanded] = useState(false);
    console.log("statusButtons");
    return(
      <Pressable style={styles.autoWidth}>
        <Menu
          visible={expanded}
          onDismiss={() => {setExpanded(false)}}
          style={styles.menu}
          anchor={
              <Button
                style={styles.input}
                mode="outlined"
                onPress={()=>{console.log("setExpanded:" + expanded);setExpanded(true)}}
              >Move to...</Button>
          }>
          { element.status !=""? <List.Item 
                left={props => <List.Icon  {...props} icon="pencil" />}
                title="Open"
                style={styles.menuItem} 
                onPress={() => { setExpanded(false); changeCategory(element, "", goToTab)}}
                />:<View/>
          }
          { element.status !="review"? <List.Item 
                left={props => <List.Icon  {...props} icon="checkbox-marked-outline" />}
                title="Review"
                style={styles.menuItem} 
                onPress={() => { setExpanded(false); changeCategory(element, "review", goToTab)}}
                />:<View/>
          }
          { (element.status !="" && element.status !="archived") ? <List.Item 
                left={props => <List.Icon  {...props} icon="archive" />}
                title="Archived"
                style={styles.menuItem} 
                onPress={() => { setExpanded(false); changeCategory(element, "archived", goToTab)}}
                />:<View/>
          }
          { <List.Item 
                left={props => <List.Icon  {...props} icon="delete" />}
                title="Delete"
                style={styles.menuItem} 
                onPress={() => { setExpanded(false); setDialog({show:true, msg:"Delete this evaluation?", action:() => {deleteRecord(element); }}); }}
                />
          }
        </Menu>
      </Pressable>
    );
  }

  const ClientList = (props) => {
    const goToTab = useTabNavigation();
    window.goToTab = goToTab;
    window.setCategory = setCategory;

    const OpenAssessment = (item) => {
      const element = item.item;
  
      var target = "Evaluation";
      if (element.status == "review")
        target = "ReportEditor";
      else if (element.status == "archived")
        target = "ReportViewer";
      else if (element.status == "template")
        target = "ReportTemplateEditor";
 
      
      return (
        <List.Item
        title={element["name_first"] && element["name_first"] != "" ? element["created"] + " : " + element["name_last"] + ", " + element["name_first"] : element["due_date"] + " : " + element["name_last"]}
          onPress={() => {console.log("target:" + target); navigation.navigate(target, { data: element, templates: window.pa.templates, changeCategory:{changeCategory}});}}
          right={()=>
            <View style={styles.listButtons}>
              {statusButtons(element)}
            </View>
        }
        />
      );
    }
  
    return (
      <FlatList
        ItemSeparatorComponent={Divider}
        data={window.pa.clients.filter( element => element.status==props.category )}
        renderItem={OpenAssessment}
        keyExtractor={ (item, index) => {if (!("uuid" in item)) item["uuid"] = uuidv4(); return(item["uuid"])}}
        extraData={category}
      />

    );
  }

  const categories=(value)=> {
    //console.log("categories:" + value);
    if (value >= 0)
      value = 10;
    return(
      <TabsProvider
        defaultIndex={0}
        //onChangeIndex={(newIndex) => {console.log("newIndex:" + newIndex)}} // react on index change
      >
        <Tabs
        // uppercase={false} // true/false | default=true | labels are uppercase
        // showTextLabel={false} // true/false | default=false (KEEP PROVIDING LABEL WE USE IT AS KEY INTERNALLY + SCREEN READERS)
        // iconPosition // leading, top | default=leading
        // style={{ backgroundColor:'#fff' }} // works the same as AppBar in react-native-paper
        // dark={false} // works the same as AppBar in react-native-paper
        // theme={} // works the same as AppBar in react-native-paper
        // mode="scrollable" // fixed, scrollable | default=fixed
        // showLeadingSpace={true} //  (default=true) show leading space in scrollable tabs inside the header
        // disableSwipe={false} // (default=false) disable swipe to left/right gestures
        >
          <TabScreen label="Open" icon="pencil" onPress={() => setCategory("")}>
            <ClientList category="" updated={updated}/>
          </TabScreen>
          <TabScreen label="Review" icon="checkbox-marked-outline" onPress={() => {console.log("review");setCategory("review")}}>
            <ClientList category="review" updated={updated}/>
          </TabScreen>
          <TabScreen label="Archived" icon="archive" onPress={() => setCategory("archived")}>
            <ClientList category="archived" updated={updated}/>
          </TabScreen>
          {/* <TabScreen label="Templates" icon="file" onPress={() => navigation.navigate("Attachments")}>
            <View/>
          </TabScreen> */}
          <TabScreen label="Templates" icon="file" onPress={() => navigation.navigate("ReportEditor", {"fileData": 
            {
                "title": "AutismPreschoolReport",
                "urlx": "https://psychassist.providentpathways.com/templates/files/AutismPreschoolReport.html",
                "url": "reports/default.html",
                "saveUrlx": "https://psychassist.providentpathways.com/upload_json_action",
                "saveUrl": "https://psychassist.providentpathways.com/upload_json_action",
                "fileNamex": "AustismPreschoolReport.html",
                "fileName": "default.html"
            }
            })}>
            <View/>
          </TabScreen>
          {/* <TabScreen label="Templates" icon="file">
            <ClientList category="templates" updated={updated}/>
          </TabScreen> */}
        </Tabs>
      </TabsProvider>
    )
  }

  const footer = () =>{
    return(
      <Appbar style={styles.appbar}>
        <Appbar.Action
          icon="logout"
          onPress={() =>  {logout()}}
        />
        
      </Appbar>
    );
  }

  return (
    <View style={styles.body}>
      <Text style={styles.title}>Psych Assist</Text>
      {/* <Text style={styles.title}>Process Validation</Text> */}
      {categories(updated)}
      <Divider/>
      {footer()}
      <FAB
        style={styles.fab}
        //small
        icon="plus"
        onPress={() => CreateNewAssessment()} 
      />
      <Portal>
        <Dialog visible={dialog.show} >
          <Dialog.Content>
            <Paragraph>{dialog.msg}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            { dialog.action ? <Button onPress={() => { setDialog({show:false}); dialog.action(); }}>Yes</Button> : <View/> }
            <Button onPress={()=>setDialog({show:false})}>No</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
}

const styles = StyleSheet.create({
  fab: {
    //backgroundColor: "",
    position: 'absolute',
    margin: 20,
    right: 0,
    bottom: 0,
  },
  body: {
    //backgroundColor: "white",
    height: "100%"
  },
  title:{
    marginTop: 60,
    color:"black",
    textAlign:"center",
    paddingBottom:40,
    fontWeight: "bold",
    fontSize:40
  },
  image: {
    flex: 1,
    padding:20,
    backgroundColor: "white"
  },
  categoryButtons:{
    flexDirection:"row",
    justifyContent:"space-around",
    marginBottom:50
  },
  listButtons:{
    flexDirection:"row",
    // justifyContent:"center",
    alignItems:"center",
    margin:0
  }
});