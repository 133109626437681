import React, { memo, useState } from 'react';
import { TouchableOpacity, StyleSheet, Text, View, Image, ImageBackground} from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import { nameValidator, emailValidator, passwordValidator, login } from '../../core/utils';

export default function Login( {navigation, route} ){
  const Logo = require("../../psych_assist.png");

  const _username = ((route.params && route.params.username) ? route.params.username : '');
  const [username, setUsername] = useState({ value: _username, error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });

  const _onLoginPressed = () => {
    var usernameError = nameValidator(username.value);
    var passwordError = passwordValidator(password.value);

    // if (username.value != "drewc" || password.value != "drewc") {
    //   usernameError = "Invalid username or password.";
    // }


    if (usernameError || passwordError) {
      setUsername({ ...username, error: usernameError });
      setPassword({ ...password, error: passwordError });
      return;
    }

    var sessionid = "on";
    login(username.value, password.value, sessionid, navigation);
    setPassword({value: '', error: ''});
  };

  return (
      <View style={styles.back}>
        <Image source={Logo} resizeMode="contain" style={styles.image}/>
        <Text style={styles.header}>Psych Assist</Text>
        {/* <Text style={styles.header}>Process Validation</Text> */}

        <TextInput
          label="Username"
          returnKeyType={"next"}
          value={username.value}
          style={styles.input}
          onChangeText={text => setUsername({ value: text, error: '' })}
          error={!!username.error}
          errorText={username.error}
          autoCapitalize="none"
          autoCompleteType="username"
          //autoFocus={(_username === '') ? true : false}
          autoFocus={true}
        />

        <TextInput
          label="Password"
          returnKeyType="go"
          onSubmitEditing={_onLoginPressed}
          value={password.value}
          style={styles.input}
          onChangeText={text => setPassword({ value: text, error: '' })}
          error={!!password.error}
          errorText={password.error}
          secureTextEntry
        />

        <View style={styles.forgotPassword}>
          <TouchableOpacity
            onPress={() => navigation.navigate('PasswordReset')}
          >
            <Text style={styles.label}>Forgot your password?</Text>
          </TouchableOpacity>
        </View>

        <Button mode="contained" onPress={_onLoginPressed}>
          Login
        </Button>
      </View>
  );
};

const styles = StyleSheet.create({
  back: {
    flex: 1,
    padding: 40,
    backgroundColor: "white"
  },
  header: {
    textAlign:"center",
    paddingBottom:50,
    fontWeight: "bold",
    fontSize:40
  },
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  label: {
    // color: theme.colors.secondary,
  },
  link: {
    fontWeight: 'bold',
    // color: theme.colors.primary,
  },
  image: {
    flex: 1,
    margin: 20,
  },
  input:{
    margin:10
  }
});
