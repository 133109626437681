import React, { useState } from 'react'
import { View , ScrollView} from 'react-native';
import { Portal, Dialog, Button} from 'react-native-paper';
import {styles} from './styles'
import Template from './Template'

export default function SettingsEditor({settingsEditor, setSettingsEditor, refreshPage}) {
  if (!settingsEditor || !settingsEditor.show)
    return(<View/>);
  const element = JSON.parse(JSON.stringify(settingsEditor.template[settingsEditor.index]));
  const [stateT, setStateT] = useState(element);
    
  const onFieldChange = (name, value)=> {
    if (value === undefined)
      value = (stateT[name] == "Y") ? "N" : "Y";
//    console.log(element);
//    console.log(stateT);
//    console.log("name:" + name + " value:" + value);
    if (value)
      setStateT(prevStateT => ({
        ...prevStateT,
        [name]: value
      }));
  };

  const checkboxStatus = (name) => {
    (stateT[name] == "Y") ? "checked" : "unchecked";
  }

  const editorTemplate = 
    [
      {
        "id": "id",
        "label": "Id",
        "type": "field",
        "placeholder": "Unique identifier",
        "editable": "Y"
      },
      {
        "id": "label",
        "label": "Label",
        "type": "field",
        "placeholder": "Descriptive prompt",
        "editable": "Y"
      },
      {
        "id": "type",
        "label": "Type",
        "type": "selection",
        "options": "title|text|field|date|image|checkbox|switch|group|selection|combobox",
        "default": "field",
        "editable": "Y"
      }
    ];
    const Default={
      "id": "default",
      "label": "Default",
      "type": "field",
      "placeholder": "Optional default value",
      "editable": "Y"
    }
    const placeholder={
      "id": "placeholder",
      "label": "Placeholder",
      "type": "field",
      "placeholder": "Optional hint",
      "editable": "Y"
    }
    const multiline={
      "id": "multiline",
      "label":"Multiline",
      "type": "field",
      "placeholder": "Field height",
      "editable": "Y"
    }
    const options={
      "id": "options",
      "label":"Options",
      "type": "field",
      "placeholder": "Items to choose from, separated by |",
      "editable": "Y"
    }
    const required={
      "id": "required",
      "label":"Required - Field must have a value",
      "type": "checkbox"
    }
    const editable={
      "id": "editable",
      "label":"Editable - Field can be changed",
      "type": "checkbox"
    }
    const expanded={
      "id": "expanded",
      "label":"Expanded - Group initially open",
      "type": "checkbox"
    }
    const overwrite={
      "id": "overwrite",
      "label":"Overwrite - Default will overwrite value",
      "type": "checkbox"
    }
    const multiSelect={
      "id": "multiSelect",
      "label":"Selection box will stay open until dismissed",
      "type": "checkbox"
    }
    const addToReport={
      "id": "addToReport",
      "label":"Field will show 'add to report' icon",
      "type": "checkbox"
    }
    if(stateT.type=="text"){
      // editorTemplate.push(placeholder);
      // editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      // editorTemplate.push(options);
      // editorTemplate.push(required);
      // editorTemplate.push(editable);
      // editorTemplate.push(multiSelect);
      // editorTemplate.push(addToReport);

    }
    else if(stateT.type=="field"){
      editorTemplate.push(Default);
      editorTemplate.push(placeholder);
      editorTemplate.push(multiline);
      // editorTemplate.push(options);
      editorTemplate.push(overwrite);
      editorTemplate.push(required);
      editorTemplate.push(editable);
      // editorTemplate.push(expanded);
      // editorTemplate.push(multiSelect);
      editorTemplate.push(addToReport);
    }
    else if(stateT.type=="date"){
      editorTemplate.push(Default);
      editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      // editorTemplate.push(options);
      editorTemplate.push(overwrite);
      editorTemplate.push(required);
      editorTemplate.push(editable);
      // editorTemplate.push(expanded);
      // editorTemplate.push(multiSelect);
      editorTemplate.push(addToReport);

    }
    else if(stateT.type=="image"){
      editorTemplate.push(Default);
      // editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      // editorTemplate.push(options);
      editorTemplate.push(overwrite);
      editorTemplate.push(required);
      // editorTemplate.push(editable);
      // editorTemplate.push(expanded);
      // editorTemplate.push(multiSelect);
      // editorTemplate.push(addToReport);
    }
    else if(stateT.type=="checkbox"){
      // editorTemplate.push(Default);
      // editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      // editorTemplate.push(options);
      editorTemplate.push(overwrite);
      editorTemplate.push(required);
      // editorTemplate.push(editable);
      // editorTemplate.push(expanded);
      // editorTemplate.push(multiSelect);
      editorTemplate.push(addToReport);
    }
    else if(stateT.type=="switch"){
      // editorTemplate.push(Default);
      // editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      // editorTemplate.push(options);
      editorTemplate.push(overwrite);
      editorTemplate.push(required);
      // editorTemplate.push(editable);
      // editorTemplate.push(expanded);
      // editorTemplate.push(multiSelect);
      editorTemplate.push(addToReport);
    }
    else if(stateT.type=="group"){
      // editorTemplate.push(Default);
      // editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      // editorTemplate.push(options);
      // editorTemplate.push(overwrite);
      // editorTemplate.push(required);
      // editorTemplate.push(editable);
      editorTemplate.push(expanded);
      // editorTemplate.push(multiSelect);
      // editorTemplate.push(addToReport);
    }
    else if(stateT.type=="selection"){
      editorTemplate.push(Default);
      editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      editorTemplate.push(options);
      editorTemplate.push(overwrite);
      editorTemplate.push(required);
      // editorTemplate.push(editable);
      // editorTemplate.push(expanded);
      // editorTemplate.push(multiSelect);
      editorTemplate.push(addToReport);
    }
    else if(stateT.type== "combobox"){
      editorTemplate.push(Default);
      editorTemplate.push(placeholder);
      // editorTemplate.push(multiline);
      editorTemplate.push(options);
      editorTemplate.push(overwrite);
      editorTemplate.push(required);
      // editorTemplate.push(editable);
      // editorTemplate.push(expanded);
      editorTemplate.push(multiSelect);
      editorTemplate.push(addToReport);
    }

  return(
      <Portal>
        <Dialog visible={settingsEditor.show} onDismiss={() => setSettingsEditor({show: false})}>
          <Dialog.Title>{stateT.label ? stateT.label : "Template Field Editor"}</Dialog.Title>
          <Dialog.Content style={styles.settingsEditor}>
            <ScrollView>
              <Template templateSection={editorTemplate} data={stateT} state={stateT} onFieldChange={onFieldChange} settingsVisible={false} refreshPage={refreshPage}/>
            </ScrollView>
          </Dialog.Content>
          <Dialog.Actions style={styles.settingsActions}>
          <Button onPress={() => setSettingsEditor({show: false})}>Cancel</Button>
            <Button onPress={() => {
              //console.log(settingsEditor.template[settingsEditor.index]);
              // Add a default item to group if it does not have any.
              if (stateT.type === "group" && !("items" in stateT))
                stateT.items = [{"id": ("" + (Math.floor(Math.random() * 1000000000000000))), "label": "New Field", "type": "field"}];
              if ("id" in stateT && stateT.id === "")
                stateT.id = "" + (Math.floor(Math.random() * 1000000000000000));
              if ("label" in stateT && stateT.label === "")
                delete stateT.label;
              if ("placeholder" in stateT && stateT.placeholder === "")
                delete stateT.placeholder;
              if ("options" in stateT && stateT.options === "")
                delete stateT.options;
              if ("default" in stateT && stateT.default === "")
                delete stateT.default;
              if ("multiline" in stateT && stateT.multiline === "")
                delete stateT.multiline;
              if ("required" in stateT && (stateT.required === "" || stateT.required === "N"))
                delete stateT.required;
              if ("editable" in stateT && (stateT.editable === "" || stateT.editable === "N"))
                delete stateT.editable;
              if ("expanded" in stateT && (stateT.expanded === "" || stateT.expanded === "N"))
                delete stateT.expanded;
              if ("overwrite" in stateT && (stateT.overwrite === "" || stateT.overwrite === "N"))
                delete stateT.overwrite;
              if ("multiSelect" in stateT && (stateT.multiSelect === "" || stateT.multiSelect === "N"))
                delete stateT.multiSelect;
              settingsEditor.template.splice(settingsEditor.index, 1, JSON.parse(JSON.stringify(stateT)));
              //console.log(settingsEditor.template[settingsEditor.index]);
              setSettingsEditor({show: false});
              refreshPage();
            }}>Save</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    );
}

// "multiSelect":"N",
