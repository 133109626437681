import {StyleSheet} from 'react-native';
import { Dimensions } from 'react-native';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export {styles}

const styles = StyleSheet.create({
    body:{
      flex:1,
      backgroundColor:"white",
      // overflow:'hidden',
      // maxHeight:(windowHeight-65)
    },
    section:{
      paddingHorizontal: 20,
    },

    row:{
      justifyContent: 'space-between',
      paddingVertical: 8,
      flexDirection:"row",
      flexWrap:"wrap"
    },
    filler:{
    },
    group: {
        borderColor: "black",
        borderWidth:"1px",
        borderRadius:"5px",
        backgroundColor: "white",
        padding: 6,
    },
    accordian: {
    },
    rightSection: {
      // flex:1,
      justifyContent: 'space-between',
      // flexDirection: 'row',
      alignItems: 'center',
      // marginHorizontal: 10,
      padding: 5,
    },
    checkbox: {
      flex:1,
      fontSize:20,
      padding: 5
    },
    title:{
      fontSize:20,
      fontWeight:"bold",
      textAlign:"center",
      padding: 5
    },
    text:{
      fontWeight:"bold",
    },
    textValue:{
      //whiteSpace:"pre-line",
    },
    idText: {
      zIndex: "9999",
      color: "red",
      position: "absolute",
      left: "0",
      //float:"left",
      marginTop: "-15px;"
    },
    input:{
      // flex: 1,
      // justifyContent: 'space-between',
      width:"100%",
      backgroundColor: "white",
    },
    autoWidth:{
      flex:1
    },
    addToReport:{
      flexDirection: 'row',
      //justifyContent:"center",
      margin: 0,
      //marginLeft: 20,
      //backgroundColor: "green",
    },
    addToReportIcon:{
      //margin: -2,
      marginRight: -10,
      opacity: 1.0
    },
    addToReportIconOff:{
      //margin: -2,
      marginRight: -10,
      opacity: 0.25
    },
    settings:{
      flexDirection: 'row',
      justifyContent:"center",
      margin: 0,
      marginLeft: 20,
      backgroundColor: "pink",
    },
    settingsIcon:{
      margin: -2,
    },
    settingsEditor:{
      maxHeight:"85%"
    },
    settingsActions:{
    },
    imgCard:{
      flex:1,
    },
    menu:{
      width:"90%",
      // padding: 100
      // marginHorizontal: "5%"
      border: "1px solid lightgrey",
      boxShadow: "5px 5px 1px lightgrey",
      marginLeft:"2em"
    },
    menuItem:{
      // width: 500,
      // backgroundColor:"blue"
      // paddingHorizontal: 300
    },

    appbar: {
      backgroundColor: "#C3B1E1",
    }

  });