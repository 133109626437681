import React, { memo, useState } from 'react';
import { TouchableOpacity, StyleSheet, Text, View, Image, ImageBackground} from 'react-native';
import { TextInput, Button, DataTable } from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';

// import { titleValidator, emailValidator, passwordValidator } from '../../core/utils';
var fileData=[
    {
        "title": "AutismPreschoolReport",
        "url": "https://psychassist.providentpathways.com/templates/files/AutismPreschoolReport.html",
        "saveUrl": "https://psychassist.providentpathways.com/upload_json_action",
        "fileName": "AustismPreschoolReport.html"
    },
    {
        "title": "drewc:default",
        "url": "https://psychassist.providentpathways.com/data/drewc/reports/default.html",
        "saveUrl": "https://psychassist.providentpathways.com/data/drewc/upload_json_action",
        "fileName": "default.html"
    },
    {
        "title": "File 3"
    }
]

export default function Attachments({navigation}){
  const Logo = require("../../../psych_assist.png");
//   const optionsPerPage = [2, 3, 4];
//   const [page, setPage] = React.useState<number>(0);
//   const [itemsPerPage, setItemsPerPage] = React.useState(optionsPerPage[0]);

//   React.useEffect(() => {
//     setPage(0);
//   }, [itemsPerPage]);

    const editFile=(id)=> {
        console.log(fileData[id]);
        navigation.navigate("ReportEditor", {"fileData": fileData[id]});
    }

    const getFile = async () => {
        console.log("something happened")
        var newFile = {title: ""}
        const res = await DocumentPicker.getDocumentAsync();
        if (res.type === "success") {
            console.log("it worked", res);
            console.log(fileData.length)
            newFile.title = res.name;
            fileData.push(newFile);
            console.log(fileData)
        } else {
            console.log("it broke", res.type);
        }
    };

    const deleteFile=(id)=>{
        fileData.splice(id, 1);
        console.log("delete ", fileData);
        refreshPage();
    }

    const listActions = (id) =>{
        return(
            <View style={styles.actions}>
                <DataTable.Cell onPress={()=>editFile(id)}>Edit</DataTable.Cell>
                <DataTable.Cell onPress={()=>deleteFile(id)}>Delete</DataTable.Cell>
            </View>
        )
    }

    const fileList = (element, key) =>{
        return (
            <DataTable.Row key={key} onPress={()=>{}}>
                <DataTable.Cell>{element.title}</DataTable.Cell>
                {listActions(key)}
            </DataTable.Row>
        )
    }

    const attachmentTable = () => {
        return(
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title>title</DataTable.Title>
                    <DataTable.Title>Actions</DataTable.Title>
                </DataTable.Header>

                {fileData.map((element, key)=>fileList(element, key))}

                <DataTable.Pagination
                page={1}
                numberOfPages={1}
                onPageChange={page => {
                    console.log(page);
                }}
                numberOfItemsPerPageList="10"
                label={"1-3 of "+fileData.length}
                />
            </DataTable>
        )
    }
    const refreshPage=()=>{
        // This is needed to cause a re-creation (intial re-render) of all elements since we have modified the page fields and not just values.
        navigation.pop();
        navigation.push("Attachments");
      };

    return (
        <View style={styles.back}>
            {attachmentTable()}
            <Button onPress={getFile}>Upload</Button>
        </View>
    );
};

const styles = StyleSheet.create({
    back: {
        flex: 1,
        padding: 40,
        backgroundColor: "white"
    },
    header: {
        textAlign:"center",
        paddingBottom:50,
        fontWeight: "bold",
        fontSize:40
    },
    row: {
        flexDirection: 'row',
        marginTop: 4,
    },
    label: {
        // color: theme.colors.secondary,
    },
    link: {
        fontWeight: 'bold',
        // color: theme.colors.primary,
    },
    image: {
        flex: 1,
        margin: 20,
    },
    input:{
        margin:10
    },
    actions:{
        flex: 1,
        flexDirection: 'row'
    }
});